import React, { Fragment, useState, useEffect } from "react";
import EliteCardService from "../../services/elite-cards-service";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import formatNumber from "../../utils/formatNumber";
import { setTableSortingData } from "../../actions/tableListing/tableSortingActionData";

const EliteDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );
  const [totalOrder, setTotalOrder] = useState(0);
  const [isCardLoader, setIsCardLoader] = useState(false);
  const [isPaymentCardLoader, setIsPaymentCardLoader] = useState(false);
  const [totalMaterialItems, setTotalMaterialItems] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalQuantityMT, setTotalQuantityMT] = useState(0);
  const [isO2WHover, setIsO2WHover] = useState(false);
  const [isLogisticWHover, setIsLogisticWHover] = useState(false);
  const [isPaymentHover, setIsPaymentHover] = useState(false);
  const [paymentTileCounts, setPaymentTileCounts] = useState({});
  const [logisticTileCounts, setLogisticTileCounts] = useState({});

  useEffect(() => {
    handleGetO2WDashboardCount();
    handleGetPaymentDashboardCount();
  }, []);

  //// API calling function code start

  //// get the count of O2W and Logistics Tile API
  const handleGetO2WDashboardCount = () => {
    setIsCardLoader(true);
    let inputData = {
      regional_Manager: "",
      general_Manager: "",
      area_Manager: "",
      company_Code: companyDetails ? companyDetails.company_Code : "",
    };
    EliteCardService.getO2wTileDashboardCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          setIsCardLoader(false);
          setTotalOrder(objData.order_section.total);
          setTotalMaterialItems(objData.material_section.total);
          setTotalQuantity(objData.quantity_section.total);
          setTotalQuantityMT(objData.quantity_in_MT.total);
          setLogisticTileCounts(objData.salesSection);
        } else {
          setIsCardLoader(false);
          setLogisticTileCounts({});
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsCardLoader(false);
        console.log(error, "Error");
      });
  };
  /// get the count of Payment Tile APi
  const handleGetPaymentDashboardCount = () => {
    setIsPaymentCardLoader(true);
    let inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: "",
    };
    EliteCardService.getPaymentTileCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          setIsPaymentCardLoader(false);
          setPaymentTileCounts(objData);
        } else {
          setIsPaymentCardLoader(false);
          setPaymentTileCounts({});
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsPaymentCardLoader(false);
        console.log(error, "1Error");
      });
  };
  //// API calling function code end

  const handleCardClick = (page) => {
    if (page === "O2W") {
      navigate("/dashboard/all");
      dispatch(
        setTableSortingData({
          sortColumn: "customer_PO_Date", /// default sort column
          orderBy: "desc",
          currentPage: 1,
        })
      );
    } else {
      navigate("/payment/customer");
    }
  };

  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isO2WHover ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("O2W")}
                  onMouseEnter={() => setIsO2WHover(true)}
                  onMouseLeave={() => setIsO2WHover(false)}
                >
                  <Spin
                    wrapperClassName="loader"
                    size="large"
                    spinning={isCardLoader}
                  >
                    <div className="card-content">
                      <div className="card-body dash_cards">
                        <h4 className="text-info cards o2w_card">
                          O2W (Order to Warehouse)
                        </h4>
                        <div className="card_lbl">
                          <label className="Lbold">Order</label>
                          <label>{totalOrder}</label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">Material Line Item</label>
                          <label>{totalMaterialItems}</label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">Quantity</label>
                          <label>{totalQuantity}</label>
                        </div>
                        {userDetails.isEmployee && (
                          <div className="card_lbl">
                            <label className="Lbold">Quantity in MT</label>
                            <label>{totalQuantityMT}</label>
                          </div>
                        )}
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>

              {/* <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isPaymentHover ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("payment")}
                  onMouseEnter={() => setIsPaymentHover(true)}
                  onMouseLeave={() => setIsPaymentHover(false)}
                >
                  <Spin
                    wrapperClassName="loader"
                    size="large"
                    spinning={isPaymentCardLoader}
                  >
                    <div className="card-content">
                      <div className="card-body dash_cards">
                        <h4 className="text-info cards">Payment</h4>
                        <div className="card_lbl">
                          <label className="Lbold">Pending Invoice Amount</label>
                          <label>
                            {Object.keys(paymentTileCounts).length > 0
                              ? paymentTileCounts.totalPendingAmount_LCY
                              : 0}
                          </label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">
                          Pending Amount
                          </label>
                          <label style={{ whiteSpace: "nowrap" }}>
                            {Object.keys(paymentTileCounts).length > 0
                              ? formatNumber(paymentTileCounts.customerCount)
                              : 0}
                          </label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">Overdue Amount</label>
                          <label>
                            {Object.keys(paymentTileCounts).length > 0
                              ? formatNumber(paymentTileCounts.overDueCount)
                              : 0}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isLogisticWHover ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("O2W")}
                  onMouseEnter={() => setIsLogisticWHover(true)}
                  onMouseLeave={() => setIsLogisticWHover(false)}
                >
                  <Spin
                    wrapperClassName="loader"
                    size="large"
                    spinning={isCardLoader}
                  >
                    <div className="card-content">
                      <div className="card-body dash_cards">
                        <h4 className="text-info cards o2w_card">Logistics</h4>
                        <div className="card_lbl">
                          <label className="Lbold">Pending Dispatch</label>
                          <label>{totalMaterialItems}</label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">In Transit</label>
                          <label>
                            {Object.keys(logisticTileCounts).length > 0
                              ? formatNumber(
                                  logisticTileCounts.dispatchQtyCount
                                )
                              : 0}
                          </label>
                        </div>
                        {userDetails.isEmployee && (
                          <div className="card_lbl">
                            <label className="Lbold">Delivered</label>
                            <label>
                              {Object.keys(logisticTileCounts).length > 0
                                ? formatNumber(
                                    logisticTileCounts.pendingSalesQtyCount
                                  )
                                : 0}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </Spin>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EliteDashboard;
